/* import __COLOCATED_TEMPLATE__ from './answer-loading-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const AnswerLoadingState = templateOnlyComponent<Signature>();
export default AnswerLoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::LoadingStates::AnswerLoadingState': typeof AnswerLoadingState;
  }
}
