/* import __COLOCATED_TEMPLATE__ from './question-filters.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Playground } from 'embercom/lib/fin-playground/playground';
import {
  type PlaygroundQuestion,
  CustomerAnswerRating,
  AnswerState,
  ANSWER_STATE_ANSWER_TYPE_MAP,
} from 'embercom/lib/fin-playground/question';
import { tracked, TrackedObject } from 'tracked-built-ins';

interface QuestionFiltersArgs {
  questions: PlaygroundQuestion[];
  filteredQuestions: PlaygroundQuestion[];
  isPlaygroundActive: boolean;
  updateQuestionRatingFilter: (rating: CustomerAnswerRating | null) => void;
  updateAnswerTypeFilter: (filter: AnswerState | null) => void;
  questionFilters: Playground['questionFilters'];
  setQuestionIndex: (index: number) => void;
  selectedQuestionId?: number;
}

type QuestionFiltersSignature = {
  Args: QuestionFiltersArgs;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
};

export default class QuestionFilters extends Component<QuestionFiltersSignature> {
  CustomerAnswerRating = CustomerAnswerRating;
  AnswerState = AnswerState;
  @tracked hoverStates = new TrackedObject({
    [CustomerAnswerRating.Positive]: false,
    [CustomerAnswerRating.Negative]: false,
    [AnswerState.Answered]: false,
    [AnswerState.Unanswered]: false,
  });

  get countOfFilteredQuestions(): number {
    return this.args.filteredQuestions.length;
  }

  get countOfFilteredQuestionsWithoutErrors(): number {
    return this.args.filteredQuestions.filter((question) => !question.answerHasError).length;
  }

  @action updateHoverState(filter: CustomerAnswerRating | AnswerState, hoverState: boolean) {
    this.hoverStates[filter] = hoverState;
  }

  @action updateSelectedQuestionIndex(currentlySelectedQuestionId?: number) {
    let newIndexOfSelectedQuestion = this.args.filteredQuestions.findIndex(
      (question: PlaygroundQuestion) => question.id === currentlySelectedQuestionId,
    );

    if (newIndexOfSelectedQuestion !== -1) {
      this.args.setQuestionIndex(newIndexOfSelectedQuestion);
    } else {
      this.args.setQuestionIndex(0);
    }
  }

  @action updateQuestionRatingFilter(rating: CustomerAnswerRating) {
    let currentlySelectedQuestionId = this.args.selectedQuestionId;

    if (rating === this.args.questionFilters.ratingFilter) {
      // Toggle filter off if it's already on
      this.args.updateQuestionRatingFilter(null);
    } else {
      this.args.updateQuestionRatingFilter(rating);
    }

    this.updateSelectedQuestionIndex(currentlySelectedQuestionId);
  }

  @action updateAnswerTypeFilter(filter: AnswerState) {
    let currentlySelectedQuestionId = this.args.selectedQuestionId;

    if (filter === this.args.questionFilters.answerTypeFilter) {
      // Toggle filter off if it's already on
      this.args.updateAnswerTypeFilter(null);
    } else {
      this.args.updateAnswerTypeFilter(filter);
    }

    this.updateSelectedQuestionIndex(currentlySelectedQuestionId);
  }

  get stats(): Record<
    | AnswerState.Answered
    | AnswerState.Unanswered
    | CustomerAnswerRating.Positive
    | CustomerAnswerRating.Negative,
    { count: number; percentage: number }
  > {
    return {
      [AnswerState.Answered]: {
        count: this.countOfQuestionsByAnswerState(AnswerState.Answered),
        percentage: this.percentageOfQuestionsByAnswerState(AnswerState.Answered),
      },
      [AnswerState.Unanswered]: {
        count: this.countOfQuestionsByAnswerState(AnswerState.Unanswered),
        percentage: this.percentageOfQuestionsByAnswerState(AnswerState.Unanswered),
      },
      [CustomerAnswerRating.Positive]: {
        count: this.countOfQuestionsByAnswerRating(CustomerAnswerRating.Positive),
        percentage: this.percentageOfQuestionsByAnswerRating(CustomerAnswerRating.Positive),
      },
      [CustomerAnswerRating.Negative]: {
        count: this.countOfQuestionsByAnswerRating(CustomerAnswerRating.Negative),
        percentage: this.percentageOfQuestionsByAnswerRating(CustomerAnswerRating.Negative),
      },
    };
  }

  countOfQuestionsByAnswerState(answerType: AnswerState): number {
    return this.args.questions.filter(
      (q) => q.answerType && ANSWER_STATE_ANSWER_TYPE_MAP[answerType].includes(q.answerType),
    ).length;
  }

  percentageOfQuestionsByAnswerState(answerType: AnswerState): number {
    return Math.round(
      (this.countOfQuestionsByAnswerState(answerType) / this.args.questions.length) * 100,
    );
  }

  countOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return this.args.questions.filter((q) => q.customerAnswerRating === rating).length;
  }

  percentageOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return Math.round(
      (this.countOfQuestionsByAnswerRating(rating) / this.args.questions.length) * 100,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionFilters': typeof QuestionFilters;
  }
}
