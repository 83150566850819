/* import __COLOCATED_TEMPLATE__ from './heading-loading-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';
import { type Playground } from 'embercom/lib/fin-playground/playground';

interface Signature {
  Args: {
    canUsePlaygroundGroups: boolean;
    playground: Playground;
  };
}

const HeadingLoadingState = templateOnlyComponent<Signature>();
export default HeadingLoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::LoadingStates::HeadingLoadingState': typeof HeadingLoadingState;
  }
}
