/* import __COLOCATED_TEMPLATE__ from './question-creation-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import {
  ExtractionType,
  type PlaygroundQuestionGeneration,
} from 'embercom/lib/fin-playground/question-generation';
import type { Task } from 'ember-concurrency';

interface Signature {
  Args: {
    hasAvailableExtractions: number | undefined;
    questionGeneration: PlaygroundQuestionGeneration;
    generateQuestions: (type: ExtractionType) => void;
    generateQuestionsTask: Task<any, any>;
    onShowUploadCSV: () => void;
    onAddQuestionsManually: () => void;
  };
}

export default class QuestionCreationEmptyState extends Component<Signature> {
  ExtractionType = ExtractionType;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionCreationEmptyState': typeof QuestionCreationEmptyState;
    'ai-agent/playground/question-creation-empty-state': typeof QuestionCreationEmptyState;
  }
}
