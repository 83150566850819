/* import __COLOCATED_TEMPLATE__ from './question-creation-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    icon: InterfaceIconName;
    heading: string;
    description: string;
    actionName: string;
    onClick: () => void;
    buttonType: 'primary' | 'secondary';
    type: 'generate-questions' | 'add-questions' | 'upload-csv';
  };
}

const QuestionCreationCard = templateOnlyComponent<Signature>();
export default QuestionCreationCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionCreationCard': typeof QuestionCreationCard;
    'ai-agent/playground/question-creation-card': typeof QuestionCreationCard;
  }
}
